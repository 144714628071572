.dialogMainText {
  font-size: var(--fontSize-base);
  font-weight: 700;
  line-height: var(--lineHeight-lg);
  margin-bottom: var(--space-2x);
}

.dialogDetail {
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-lg);
  display: grid;
  grid-template-columns: 80px 1fr;
  margin-bottom: var(--space-3x);
}

.dialogDetail > dt {
  font-weight: 700;
}

.dialogDetail > dd {
  font-weight: 400;
}

.dialogLabelText {
  font-size: var(--fontSize-sm);
  font-weight: 400;
  line-height: var(--lineHeight-base);
}
