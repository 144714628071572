.root {
  position: relative;
  display: inline-flex;
  transition: opacity var(--duration);
  max-width: 100%;
}
.root + .root {
  margin-left: var(--space-2x);
}
.root:not(.checked):hover {
  opacity: 0.8;
}
.checked .body {
  background-color: var(--color-green-light);
  border-color: var(--color-primary);
}
.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.disabled > .input {
  pointer-events: none;
}

.body {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  border: 1px solid var(--border-gray-dark);
  background-color: var(--color-white);
  font-weight: bold;
  max-width: 100%;
}
.disabled > .body {
  color: var(--color-gray-30);
  border: 1px solid var(--border-gray);
  cursor: not-allowed;
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.size--sm {
  font-size: var(--fontSize-sm);
  padding-left: var(--space-3x);
  padding-right: var(--space-3x);
  height: 32px;
}
.size--base {
  font-size: var(--fontSize-sm);
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  height: 36px;
}
.size--md {
  font-size: var(--fontSize-base);
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  height: 36px;
}

.size--mdCompact {
  font-size: var(--fontSize-base);
  padding-left: var(--space-3x);
  padding-right: var(--space-2x);
  height: 36px;
}

.label + .icon {
  margin-left: var(--space);
}

.icon + .label {
  margin-left: var(--space);
}

.iconImage {
  display: block;
  width: 16px;
  height: 16px;
  object-fit: contain;
}
