.overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.closeButton {
  position: absolute;
  top: 0;
  left: calc(50% + (375px / 2) - 40px);
  right: 0;
  padding: var(--space-2x);
  cursor: pointer;
}

.artboard {
  width: 375px;
  height: fit-content;
}

.isShort {
  width: 80%;
}

.endedContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-white);
  max-width: 90%;
}

.endedText {
  font-size: var(--fontSize-lg);
  font-weight: 800;
  line-height: var(--lineHeight-xl);
  margin-bottom: var(--space-2x);
  opacity: 0;
  animation: fadeIn 0.2s ease-out 0s forwards;
}

.endedContainer.loading button {
  /* ローディング状態でも背景色を維持 */
  background-color: var(--color-disabled);
}

.endedContainer button {
  opacity: 0;
  animation: fadeIn 0.2s ease-out 0s forwards;
}

.endedContainer > .endedText + button {
  opacity: 0;
  animation: fadeIn 0.2s ease-out 0.4s forwards;
}

.endedSupportText {
  display: flex;
  align-items: center;
  gap: var(--space-2);
  font-size: var(--fontSize-sm);
  font-weight: 700;
  line-height: var(--lineHeight-base);
  margin-top: var(--space-5x);
  opacity: 0;
  animation: fadeIn 0.2s ease-out 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
